// Custom soy templates:
require('script-loader!.tmp/js/templates/search/modules/CobaltModified/Ace.js');
require('script-loader!.tmp/js/templates/search/modules/CobaltModified/Locator.js');

import { Maps } from 'js/components/Maps/index.js';
import { LocationHours } from 'js/components/LocationHours/index.js';
import { OnReady } from 'js/components/Util/Browser.js';

import { fireGTMEvent } from 'js/common/modules/analytics/GTMAnalytics.js';
import { GTMEvents } from 'js/common/modules/analytics/GTMEvents.js';
import { RendererCobalt as RendererCobaltBase } from 'js/components/Cobalt/Locator/Cobalt/RendererCobalt.js';

class RendererCobalt extends RendererCobaltBase {
  constructor(...args) {
    super(...args);
  }

  render(data, options = { usedGeolocation: false, fireSearchEvent: true }) {
    data = Object.assign(data, this.templateParams);
    if (!data.locations) {
      this.el.innerHTML = cobalt_modified.locator.cobalt_initial(data); // modified
    } else {
      this.el.innerHTML = cobalt_modified.locator.cobalt_results(data); //modified
      LocationHours.loadAndRun();

      // make the teasers clickable
      // gross but the client insisted
      let teasers = document.querySelectorAll('.Teaser--search');
      for (let i = 0; i < teasers.length; i++){
        teasers[i].addEventListener('click', (e) => {
          let url = teasers[i].getAttribute('data-url');
          if (url.length > 0)
            window.location.href = url;
        });
      };
    }

    const locations = data ? (data.locations || []) : [];

    if (options.fireSearchEvent) {
      const searchEvent = GTMEvents.SearchEvent(locations.length, data && data.query, options.usedGeolocation);
      fireGTMEvent(searchEvent);
    }

    for (let map of Maps.instances) {
      const rerenderMap = () => {
        map.allLocations = locations.map(key => Object.assign(key.loc, {url: key.url, altTagText: ''}));
        map.updateMap();
      };

      map.removePins();
      if (map.mapIsLoaded) {
         rerenderMap();
      } else {
        map.element.addEventListener('map-ready', () => {
          rerenderMap();
        });
      }
      map.registerResultHandlers();
    }
    this.previousData = data;
  }
}

const Instance = new RendererCobalt();

export {
  RendererCobalt,
  Instance
}
