import 'babel-polyfill';
import createFocusTrap from 'focus-trap/index.js';
import { PinClickEvent, PinHoverEvent, CardHoverEvent, CardClickEvent } from './Events.js';
import { OptimizedResizeInstance } from 'js/components/Util/OptimizedResize.js';
import { Maps } from 'js/components/Maps/index.js';
import { SearchMap } from 'js/search/modules/Google.js';

export class CobaltMap extends SearchMap {
  static initClass() {
    Maps.FactoryForProvider.Google = (data) => new this(data);
    this.instances = [];
    this.providerLoaded = false;
    this.className = "Yext.Maps.CobaltMap";
    this.breakPoint = 992;
  }

  constructor(args) {
    super(args);
    this.pinHeight = 45;
    this.pinWidth = 34;
    this.pins = [];
  }

  svgPin(i, backgroundColor, textColor, isBig) {
    if (isBig) {
      return `
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="38" viewBox="0 0 32 38">
          <g fill="none" fill-rule="evenodd">
            <path fill="#0078CF" fill-rule="nonzero" d="M0 15.736c0 8.13 10.467 18.15 14.469 21.684.872.77 2.194.774 3.07.007C21.55 33.925 32 24.003 32 15.736 32 7.046 24.837 0 16 0S0 7.045 0 15.736"/>
            <text fill="#FFF" font-family="Arial-BoldMT, Arial" font-size="12" font-weight="bold">
              <tspan x="${(i >= 10) ? 9 : 12}" y="20">${i}</tspan>
            </text>
          </g>
        </svg>
      `;
    }
    return `
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="26" viewBox="0 0 22 26">
        <g fill="none" fill-rule="evenodd">
          <path fill="${backgroundColor ? backgroundColor : '#ed711c'}" fill-rule="nonzero" d="M0 10.767c0 5.563 7.196 12.418 9.947 14.836.6.527 1.509.53 2.112.005C14.815 23.212 22 16.423 22 10.767 22 4.82 17.075 0 11 0S0 4.82 0 10.767"/>
          <text fill="${textColor ? textColor : 'white'}" font-family="Arial-BoldMT, Arial" font-size="12" font-weight="bold">
            <tspan x="${(i >= 10) ? 4 : 8}" y="15">${i}</tspan>
          </text>
        </g>
      </svg>
    `;
  }

  iconImage(loc, i, backgroundColor, textColor, isBig) {
    return `data:image/svg+xml;charset=utf8,${encodeURIComponent(this.svgPin(i+1, backgroundColor, textColor, isBig))}`;
  }

  registerResultHandlers() {
    registerResultHandlers();
  }

  removePins() {
    for (let pin of this.pins) {
      pin.setMap(null);
    }
    this.pins = [];
  }

  updateMap() {
    this.boundsForPins = new google.maps.LatLngBounds();
    this.infowindow = new google.maps.InfoWindow();

    this.pins = [];
    let idx = 0;

    for (let location of Array.from(this.allLocations)) {
      let pin = this.preparePin(idx, location, this.map);
      this.boundsForPins.extend(pin.position);
      this.pins.push(pin);
      idx++;
    }
    this.redrawMap();
  }

  prepareMap() {
    const map = super.prepareMap();
    this.constructor.map = map;
    return map;
  }

  stylePin(pin) {
    const defaultText = 'white';
    const hoveredText = 'white';
    const selectedText = 'white';
    const defaultColor = 'red';
    const hoveredColor = 'green';
    const selectedColor = 'blue';

    let icon = this.iconImage(loc, i, defaultColor);
    if (pin.isSelected) {
      icon = this.iconImage(loc, i, selectedColor, 'white', selectedText, true);
      pin.setZIndex(1);
    } else if (pin.isHovered) {
      icon = this.iconImage(loc, i, hoveredColor);
      pin.setZIndex(1);
    } else {
      pin.setZIndex(0);
    }
    this.validatePinIcon(icon);
    pin.setIcon(icon);
  }

  preparePin(i, loc, m) {
    let icon = this.iconImage(loc, i);
    this.validatePinIcon(icon);
    const position = new google.maps.LatLng(loc.latitude, loc.longitude);
    let pin = new google.maps.Marker({
      position: position,
      icon,
      map: m,
      zIndex: 0,
      optimized: false // For IE <= 11 compat
    });

    pin.addListener('click', () => {
      this.clickHandler(loc, pin, i, m);
    });

    this.pins.push(pin);
    pin.isHovered = false;
    pin.isSelected = false;

    pin.addListener('mouseover', () => {
      this.hoverHandler(true, loc, pin, i, m);
    });
    pin.addListener('mouseout', () => {
      this.hoverHandler(false, loc, pin, i, m);
    })

    document.addEventListener(CardClickEvent.eventTypeName, (event) => {
      let myTarget = `js-yl-${loc.id}`;
      if (event.detail.yextId == myTarget) {
        const bounds = m.getBounds();
        if (!bounds.contains(position)) {
          m.panTo(position);
        }
        let icon = this.iconImage(loc, i, '#0078cf', 'white', true);
        this.validatePinIcon(icon);
        pin.setIcon(icon);
        pin.isSelected = true;
      } else {
        let icon = this.iconImage(loc, i, '#ed711c', 'white');
        this.validatePinIcon(icon);
        pin.setIcon(icon);
        pin.isSelected = false;
        pin.isHovered = false;
      }
    });

    document.addEventListener(PinClickEvent.eventTypeName, (event) => {
      let myTarget = `js-yl-${loc.id}`;
      if (event.detail.yextId == myTarget) {
        let icon = this.iconImage(loc, i, '#0078cf', 'white', true);
        this.validatePinIcon(icon);
        pin.setIcon(icon);
        pin.isSelected = true;
      } else {
        let icon = this.iconImage(loc, i, '#ed711c', 'white');
        this.validatePinIcon(icon);
        pin.setIcon(icon);
        pin.isSelected = false;
        pin.isHovered = false;
      }
    });

    document.addEventListener(CardHoverEvent.eventTypeName, (event) => {
      let myTarget = `js-yl-${loc.id}`;
      if (pin.isSelected) {
        pin.isHovered = event.detail.active;
        return;
      }

      if (event.detail.yextId == myTarget && event.detail.active) {
        let icon = this.iconImage(loc, i, '#0078cf', 'white');
        this.validatePinIcon(icon);
        pin.setIcon(icon);
        pin.isHovered = true;
      } else {
        let icon = this.iconImage(loc, i, '#ed711c', 'white');
        this.validatePinIcon(icon);
        pin.setIcon(icon);
        pin.isHovered = false;
      }
    });

    document.addEventListener(PinHoverEvent.eventTypeName, (event) => {
      let myTarget = `js-yl-${loc.id}`;
      if (pin.isSelected) {
        pin.isHovered = event.detail.active;
        return;
      }
      if (event.detail.yextId == myTarget && event.detail.active) {
        let icon = this.iconImage(loc, i, '#0078cf', 'white');
        this.validatePinIcon(icon);
        pin.setIcon(icon);
        pin.isHovered = true;
      } else {
        let icon = this.iconImage(loc, i, '#ed711c', 'white');
        this.validatePinIcon(icon);
        pin.setIcon(icon);
        pin.isHovered = false;
      }
    });

    return pin;
  }
}
CobaltMap.initClass();

const registerResultHandlers = () => {
  let results = document.getElementsByClassName('js-location-result');
  for (let result of Array.from(results)) {
    result.addEventListener('click', function() {
      const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.CobaltMap.breakPoint}px)`);
      if (!minWidthSearchMapBreakPointQuery.matches) { return; }
      document.dispatchEvent(new CardClickEvent(this.getAttribute('id')));
    });
    result.addEventListener('mouseover', function() {
      const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.CobaltMap.breakPoint}px)`);
      if (!minWidthSearchMapBreakPointQuery.matches) { return; }
      document.dispatchEvent(new CardHoverEvent(this.getAttribute('id'), true));
    });
    result.addEventListener('mouseout', function() {
      const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.CobaltMap.breakPoint}px)`);
      if (!minWidthSearchMapBreakPointQuery.matches) { return; }
      document.dispatchEvent(new CardHoverEvent(this.getAttribute('id'), false));
    });
  }
}

const registerDocumentHandlers = () => {
  document.addEventListener(PinClickEvent.eventTypeName, function(e) {
    let id = e.detail.yextId;
    if (id == null) { return; }
    const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.CobaltMap.breakPoint}px)`);
    if (minWidthSearchMapBreakPointQuery.matches) {
      document.getElementById(id).classList.add('is-selected');
    }
    let toRemove = document.querySelectorAll(`.js-location-result.is-selected:not(#${id})`);
    for (let toChange of Array.from(toRemove)) {
      toChange.classList.remove('is-selected');
      toChange.classList.remove('is-hovered');
    }

    const isIE11 =!!window.MSInputMethodContext && !!document.documentMode;
    const stickyHeight = isIE11 ? 0 : document.querySelector('.Locator-searchWrapper').offsetHeight;

    const targetEl = document.getElementById(id);

    if (document.querySelector('html').classList.contains('chaz')) {
      function isScrolledIntoView(elem) {
        const elBottom = $(elem).position().top + elem.offsetHeight;
        return (elBottom <= (window.scrollY + window.innerHeight)) && ($(elem).position().top >= (window.scrollY + stickyHeight));
      }

      if (!isScrolledIntoView(targetEl)) {
        $('html ,body').animate({
          scrollTop: $(targetEl).offset().top - document.querySelector('header').offsetHeight - stickyHeight,
        }, {duration: 600, queue: false});
      }
    } else if (document.querySelector('html').classList.contains('ace')) {
      const container = document.querySelector('.Locator-content');
      function isScrolledIntoView(elem) {
        const elBottom = $(elem).position().top + elem.offsetHeight;
        return (elBottom <= container.offsetHeight) && ($(elem).position().top >= stickyHeight);
      }

      if (!isScrolledIntoView(targetEl)) {
        $('.Locator-content').animate({
            scrollTop: container.scrollTop + $(targetEl).offset().top - document.querySelector('header').offsetHeight - stickyHeight,
        }, {duration: 600, queue: false});
      }
    }
  });

  document.addEventListener(CardClickEvent.eventTypeName, function(e) {
    let id = e.detail.yextId;
    if (!id) { return; }
    const targetEl = document.getElementById(id);
    let selector = `.js-location-result.is-selected`;
    let toRemove = document.querySelectorAll(selector);
    for (let toChange of Array.from(toRemove)) {
      toChange.classList.remove('is-selected');
    }
    targetEl.classList.add('is-selected');
  });

  document.addEventListener(PinHoverEvent.eventTypeName, function(e) {
    let id = e.detail.yextId;
    const targetEl = document.getElementById(id);
    if (e.detail.active) {
      targetEl.classList.add('is-hovered');
    }
    let selector = `.js-location-result.is-hovered`;
    if (e.detail.active) {
      selector += `:not(#${id})`;
    }
    let toRemove = document.querySelectorAll(selector);
    for (let toChange of Array.from(toRemove)) {
      toChange.classList.remove('is-hovered');
    }
  });
}

export const MapSetup = () => {
  registerResultHandlers();
  registerDocumentHandlers();
}
