export class ReverseGeolocation {
  static initClass() {

    this.inflight = false;
    this.successes = [];
    this.failures = [];
    this.url = '/geocode-ip';
  }

  static getCurrentLocation(success, failure) {
    if (this.cached) { return success(this.cached.lat, this.cached.long); }
    if (success != null) { this.successes.push(success); }
    if (failure != null) { this.failures.push(failure); }
    if (this.inflight) { return; }
    this.inflight = true;
    return this.geolocate((latitude, longitude)  => {
      this.successes.forEach(element => element(latitude, longitude));
      this.successes = [];
      this.failures = [];
      return this.inflight = false;
    }
    , error => {
      this.failures.forEach(element => element(error));
      this.successes = [];
      this.failures = [];
      return this.inflight = false;
    });
  }

  static geolocate(success, failure) {
    return $.getJSON(this.url, data => {
      this.cached = {latitude: data.latitude, longitude: data.longitude};
      if (success != null) { return success({latitude: data.latitude, longitude: data.longitude}); }
    }
    , function(error) {
      if (failure != null) { return failure(error); }
    });
  }
}
