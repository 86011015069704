require('script-loader!.tmp/js/templates/components/Util/Util.js');
require('script-loader!.tmp/js/templates/components/Maps/Maps.js');
require('script-loader!.tmp/js/templates/components/Maps/GetDirections.js');
require('script-loader!.tmp/js/templates/components/address/address.js');
require('script-loader!.tmp/js/templates/components/address/i18n.js');
require('script-loader!.tmp/js/templates/components/address/fields.js');
require('script-loader!.tmp/js/templates/components/phone/phone.js');
require('script-loader!.tmp/js/templates/components/search/search.js');
require('script-loader!.tmp/js/templates/components/LocationHours/LocationHours.js');
require('script-loader!.tmp/js/templates/common/helpers.js');
require('script-loader!.tmp/js/templates/common/modules/Teaser.js');
require('script-loader!.tmp/js/templates/components/Cobalt/Locator/Ace.js');
require('script-loader!.tmp/js/templates/components/Cobalt/Locator/Chaz.js');
require('script-loader!.tmp/js/templates/components/Cobalt/Locator/Locator.js');
require('script-loader!.tmp/js/templates/components/Cobalt/Teaser.js');
require('script-loader!.tmp/js/templates/common/modules/Footer.js');
require('script-loader!.tmp/js/templates/common/modules/LocationName.js');
require('script-loader!.tmp/js/templates/url/url.js');

import { Maps } from 'js/components/Maps/index.js';
import { LocationHours } from 'js/components/LocationHours/index.js';
import { OnReady } from 'js/components/Util/Browser.js';

class RendererCobalt {
  constructor(el) {
    OnReady(() => {
      this.el = document.getElementById('js-locator').querySelector('.js-locator-resultsContainer');
      const locatorParamsEl = document.getElementById('js-locator').querySelector('.js-locator-params');
      this.templateParams = locatorParamsEl ? JSON.parse(locatorParamsEl.innerHTML) : {};
      this.previousData = {};
    });
  }

  render(data, options = {}) {
    data = Object.assign(data, this.templateParams);
    if (!data.locations) {
      this.el.innerHTML = cobalt.locator.cobalt_initial(data);
    } else {
      this.el.innerHTML = cobalt.locator.cobalt_results(data);
      LocationHours.loadAndRun();
    }

    const locations = data ? (data.locations || []) : [];

    for (let map of Maps.instances) {
      const rerenderMap = () => {
        map.allLocations = locations.map(key => Object.assign(key.loc, {url: key.url, altTagText: ''}));
        map.updateMap();
      };

      map.removePins();
      if (map.mapIsLoaded) {
         rerenderMap();
      } else {
        map.element.addEventListener('map-ready', () => {
          rerenderMap();
        });
      }
      map.registerResultHandlers();
    }
    this.previousData = data;
  }
}

const Instance = new RendererCobalt();

export {
  RendererCobalt,
  Instance
}
