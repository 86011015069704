import { SearchElementForSelector } from 'ts/components/YextAnalytics/Analytics.ts';

export const VIDEOTRON_EVENT_NAME = 'chercher un point de vente';
export const VIDEOTRON_EVENT_CATEGORY = 'magasinage';

// Videotron would like all of their event info sent to GTM lowercased and escaped
// Escape function taken from: https://stackoverflow.com/questions/21451650/how-to-escape-and-unescape-quotes-in-javascript
const format = str => (
  str ? str.replace(/\\["']/g, "'").replace(/["']/g, "\'").toLowerCase().trim() : ''
);

// Events for components that aren't tracked through data-ya-track,
// require special functionality, or are easier to incorporate through
// Soy templates should live here
export const GTMEvents = {
  GenericEvent(eventAction, eventLabel) {
    return {
      eventAction: format(eventAction),
      eventLabel: format(eventLabel)
    };
  },
  ProductEvent(name) {
    return {
      eventAction: 'cliquer sur un service offert dans la boutique',
      eventLabel: format(name)
    };
  },
  PromoEvent(eventType, promoInfo) {
    let eventAction = '';
    if (eventType === 'click') {
      eventAction = 'cliquer sur une promotion interne';
    } else if (eventType === 'impression') {
      eventAction = 'voir une promotion interne';
    }

    return {
      eventAction: format(eventAction),
      promotion: [{
        id: format(promoInfo.gtmId),
        name: format(promoInfo.gtmName),
        position: format(promoInfo.gtmPosition),
        creative: format(promoInfo.gtmCreative)
      }]
    };
  },
  SearchEvent(numResults, searchQuery, usedGeolocation) {
    if (usedGeolocation) {
      return {
        eventAction: 'chercher un point de vente',
        eventLabel: 'utiliser la géolocalisation'
      };
    }
    else if (numResults === 0) {
      return {
        eventAction: 'recevoir aucun résultats lors de la recherche',
        eventLabel: format(searchQuery)
      };
    }
    else {
      return {
        eventAction: 'chercher un point de vente',
        eventLabel: 'chercher un point de vente'
      };
    }
  },
  UpcomingEvent(name) {
    return {
      eventAction: 'cliquer sur un événement à venir',
      eventLabel: format(name)
    };
  },
  AboutCTAEvent() {
    return {
      eventAction: 'prendre rendez-vous avec un pro',
      eventLabel: ''
    };
  },
  ApplyFiltersEvent(filters) {
    const selectedFiltersText = filters && filters.map(filter => {
      const filterTextEl = filter.nextElementSibling;
      return filter.checked && filterTextEl ? filterTextEl.innerText : '';
    }).filter(text => text).join(', ');

    return {
      eventAction: 'filtrer la recherche de point de vente',
      eventLabel: selectedFiltersText ? format(selectedFiltersText) : 'aucun'
    }
  }
};

// Events for built-in components that are tracked using data-ya-track should live here
// so new components don't have to be created to fire Google Tag Manager events
export const YEXT_EVENT_MAPPING = {
  nap_directions: {
    eventAction: 'chercher un point de vente',
    eventLabel: 'clic sur consulter l\'itinéraire sur une fiche boutique'
  },
  nearby_directions: {
    eventAction: 'cliquer sur une succursale à proximité',
    eventLabel: eventTarget => {
      const teaserElement = SearchElementForSelector(eventTarget, '.Teaser');
      const titleElement = teaserElement && teaserElement.querySelector('.Teaser-title');
  
      return titleElement && titleElement.innerText ? `voir l'itinéraire - ${titleElement.innerText}` : null;
    }
  },
  nearby_visitpage: {
    eventAction: 'cliquer sur une succursale à proximité',
    eventLabel: eventTarget => {
      const teaserElement = SearchElementForSelector(eventTarget, '.Teaser');
      const titleElement = teaserElement && teaserElement.querySelector('.Teaser-title');

      return titleElement && titleElement.innerText ? `plus d'info - ${titleElement.innerText}` : null;
    }
  },
  nap_phone: {
    eventAction: 'cliquer sur un numéro de téléphone',
    eventLabel: eventTarget => eventTarget.innerText
  },
  nearby_phone: {
    eventAction: 'cliquer sur une succursale à proximité',
    eventLabel: eventTarget => {
      const teaserElement = SearchElementForSelector(eventTarget, '.Teaser');
      const titleElement = teaserElement && teaserElement.querySelector('.Teaser-title');

      return titleElement && titleElement.innerText ? `téléphoner - ${titleElement.innerText}` : null;
    }
  },
  nearby_businessname: {
    eventAction: 'cliquer sur une succursale à proximité',
    eventLabel: eventTarget => {
      const teaserElement = SearchElementForSelector(eventTarget, '.Teaser');
      const titleElement = teaserElement && teaserElement.querySelector('.Teaser-title');

      return titleElement && titleElement.innerText ? `clic sur la succursale - ${titleElement.innerText}` : null;
    }
  }
};
