
import $ from 'jquery';
import 'slick-carousel';

class Carousel {
  constructor(element, settings = {}) {
    this.element = element;
    this.settings = settings;
    $(this.element).slick(this.settings);
  }
}

module.exports = {
  Carousel: Carousel
};
