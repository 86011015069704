import { BaseMap } from './BaseMap.js';
import { OptimizedResizeInstance } from 'js/components/Util/OptimizedResize.js';
import { Instance as MapsAPI } from './GoogleMapsAPI.js';

export class GoogleMapsEnterprise extends BaseMap {
  static initClass() {
    this.instances = [];
    this.providerLoaded = false;
    this.className = "Yext.Maps.GoogleMapsEnterprise";
    this.TILE_SIZE = { // google maps tile size as of v 3.22
      height: 256,
      width: 256
    };
    this.ZOOM_MAX = 21; // max zoom allowed by google
  }

  constructor(args) {
    super(args);
    this.infowindow = null;
    this.versionType = 'client';
    this.mapOptions = {
      zoom: this.config.zoom,
      //controls:
      disableDefaultUI: true, // disables default controls
      zoomControl: !this.config.disableMapControl, // allows user to control zooming
      scaleControl: false, // allows user to toggle scale between KM and MI
      mapTypeControlOptions: false, // allows user to change between street and satellite
      fullScreenControl: false, // allows user to make the map full screen
      streetViewControl: false, // allows user to use street view
      //interactions:
      disableDoubleClickZoom: true,
      draggable: !this.config.disableMapControl,
      scrollwheel: false,
      gestureHandling: 'auto',
      //basics
      mapTypeId: 'roadmap' // can be roadmap, satellite, hybrid, or terrain
    };
  }

  appendScript() {
    MapsAPI.setParam("v", "3.31");
    MapsAPI.setParam("channel", this.config.channelId);
    MapsAPI.setParam(this.versionType, this.config.apiID);
    MapsAPI.loaded().then(() => {
      this.constructor.providerCallback();
    });
  }

  clickHandler(loc, pin, index, map) {
    return super.clickHandler(...arguments);
  }

  iconImage(location, i) {
    let iconUrl = super.iconImage(location, i);
    return {
      url: iconUrl,
      scaledSize: new google.maps.Size(this.pinWidth,this.pinHeight)
    };
  }

  preparePin(i, loc, m) {
    let icon = this.iconImage(loc, i);
    this.validatePinIcon(icon);
    let pin = new google.maps.Marker({
      position: new google.maps.LatLng(loc.latitude, loc.longitude),
      icon,
      map: m,
      zIndex: 0,
      optimized: false // For IE <= 11 compat
    });

    pin.addListener('click', () => {
      this.clickHandler(loc, pin, i, m);
    });
    return pin;
  }

  validatePinIcon(icon) {
    if (((typeof icon === 'object') && (icon.scaledSize == null) && (icon.url.indexOf('data:image/svg+xml') > 0)) ||
       ((typeof icon === 'string') && (icon.indexOf('data:image/svg+xml') > 0))) {
      throw new Error('You must set a scaledSize for your SVG, or it will break in IE');
    }
  }

  setupMarkerClustering(map, pins) {
    if (window.MarkerClusterer != null) {
      window.markerClusterer = new MarkerClusterer(map, pins);
      window.markerClusterer.setGridSize(30);

      let defaultClustererStyles = [{
        url: `${this.config.baseUrl}${require('assets/images/icon-pin-cluster.svg')}`,
        height: 36,
        width: 23,
        anchor: [4, 0],
        textColor: '#ffffff',
        textSize: 10
      }];

      return markerClusterer.setStyles(defaultClustererStyles);
    }
  }

  updateMapDimensions() {
    this.mapDimensions.height = this.element.offsetHeight;
    return this.mapDimensions.width = this.element.offsetWidth;
  }

  getBoundsZoomLevel(bounds, dimensions) {
    let latRadian = function(lat) {
      let sin = Math.sin((lat * Math.PI) / 180);
      let radX2 = Math.log((1 + sin) / (1 - sin)) /2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    };

    let zoom = (mapPx, worldPx, fraction) => {
      if (fraction == 0) {
        return this.constructor.ZOOM_MAX;
      }
      let logInputs = Math.log(mapPx / worldPx / fraction);
      return Math.floor(logInputs / Math.LN2);
    }

    let ne = bounds.getNorthEast();
    let sw = bounds.getSouthWest();
    let latFraction = (latRadian(ne.lat()) - latRadian(sw.lat())) / Math.PI;
    let lngDiff = ne.lng() - sw.lng();
    let lngFraction = ( (lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;
    let latZoom = zoom(dimensions.height, this.constructor.TILE_SIZE.height, latFraction);
    let lngZoom = zoom(dimensions.width, this.constructor.TILE_SIZE.width, lngFraction);
    return Math.min(latZoom, lngZoom, this.constructor.ZOOM_MAX);
  }

  setMapBounds() {
    if (!(this.allLocations.length > 0)) { return; }
    let offset = this.mapOffset();
    this.updateMapDimensions();
    if (!(this.mapDimensions.height > 0) || !(this.mapDimensions.width > 0)) { return; }
    let bounds = this.boundsForPins;
    let dimensions = {
      width: this.mapDimensions.width - offset.left - offset.right,
      height: this.mapDimensions.height - offset.top - offset.bottom
    };

    if (this.allLocations.length > 1)
    {
      let zoomLevel = this.getBoundsZoomLevel(bounds, dimensions);
      this.map.setZoom(zoomLevel);
    }

    this.setOffsetCenter(bounds.getCenter(), offset);
  }

  offsetLatLng(latlng, offsetX, offsetY) {
    if (!latlng) { return; }
    offsetX = offsetX || 0;
    offsetY = offsetY || 0;
    let scale = Math.pow(2, this.map.getZoom());
    let proj = this.map.getProjection();
    let point = proj.fromLatLngToPoint(latlng);
    let pixelOffset = new google.maps.Point((offsetX/scale), (offsetY/scale));
    let newPoint = new google.maps.Point(
      point.x - pixelOffset.x,
      point.y - pixelOffset.y
    );
    return this.map.getProjection().fromPointToLatLng(newPoint);
  }

  setOffsetCenter(latlng, offset) {
    let newCenterLatLng = this.offsetLatLng(latlng, offset.left/2, offset.top/2);
    this.map.panTo(newCenterLatLng);
  }

  prepareMap() {
    this.boundsForPins = new google.maps.LatLngBounds();
    this.infowindow = new google.maps.InfoWindow();

    this.mapOptions.zoomControlOptions =
      {position: google.maps.ControlPosition.RIGHT_TOP};

    if (this.config.controlOptions != null) {
      let object = this.config.controlOptions();
      for (let option in object) {
        let config = object[option];
        this.mapOptions[option] = config;
      }
    }

    this.map = new google.maps.Map(this.element, this.mapOptions);
    this.pins = [];
    let idx = 0;

    for (let location of Array.from(this.allLocations)) {
      let pin = this.preparePin(idx, location, this.map);
      this.boundsForPins.extend(pin.position);
      this.pins.push(pin);
      idx++;
    }

    this.setupMarkerClustering(this.map, this.pins);

    google.maps.event.addListener(this.map, "click", () => {
      if (this.infowindow != null) { this.infowindow.close(); }
    });

    if (this.allLocations.length === 0) {
      window.google.maps.event.addListenerOnce(this.map, 'idle', () => {
        this.map.setZoom(this.config.zoom);
        return this.setOffsetCenter(new google.maps.LatLng({lat: this.latitude, lng: this.longitude}), this.mapOffset());
      });
    }

    window.google.maps.event.addListenerOnce(this.map, 'idle', () => {
      this.setMapBounds();
      this.mapLoaded();
    });

    OptimizedResizeInstance.on( () => {
      if(this.element.classList.contains('js-map-ready')) {
        this.setMapBounds();
      }
    });

    return this.map;
  }

  redrawMap() {
    return this.setMapBounds();
  }
}

GoogleMapsEnterprise.initClass();
