import { Warn } from 'ts/components/AnalyticsDebugger/Utils';
import { Instance } from 'js/components/YextAnalytics/Analytics.js';

let SelectorTracking = {};

function GetParams(url) {
  let queries = {};
  let parts = url.split('?');
  if (parts.length == 2) {
    parts[1].split('&').forEach((pair)=>{
      let params = pair.split('=');
      queries[params[0]] = params[1];
    });
  }
  return queries;
}

function CheckAnchorQueries(anchor) {
  if (anchor && anchor.href) {
    let eName = GetParams(anchor.href)['ya-track'];
    if (eName) {
      return eName;
    }
  }
  return false;
}

function SearchElementForSelector(el, s) {
  /* Loop up the DOM tree through parent elements to try to find an element that matches the given selector */
  while (el && (el.tagName && !el.matches(s))) {
    el = el.parentNode;
  }

  if (el && el.tagName && el.matches(s)) {
    return el;
  }

  return null;
}

function CalcEventNameForElement(element) {
  let type = null;
  let trackDetails = null;
  let srcEl = null;

  for (const selector in SelectorTracking) {
    if (!element.matches(selector)) continue;
    trackDetails = SelectorTracking[selector];
  }

  if (!trackDetails) {
    let potentialYaTrackedEl = SearchElementForSelector(element, '[data-ya-track]');
    if (potentialYaTrackedEl) {
      srcEl = potentialYaTrackedEl;
      trackDetails = potentialYaTrackedEl.dataset.yaTrack;
    }
  }

  if (!trackDetails) {
    let anchor = SearchElementForSelector(element, 'a');
    if (anchor) {
      srcEl = anchor;
      let anchorQuery = CheckAnchorQueries(anchor);
      if (anchorQuery) trackDetails = anchorQuery;
      if (!anchorQuery && !trackDetails) {
        type = 'link';
      }
    }
  }

  if (!trackDetails && !type) {
    let button = SearchElementForSelector(element, 'button');
    if (button) {
      srcEl = button;
      type = 'button';
    }
  }

  if (!trackDetails && !type) {
    let input = SearchElementForSelector(element, 'input');
    if (input && input.type != 'hidden') {
      srcEl = input;
      type = 'input';
    }
  }

  let dataYaTrack = type || trackDetails;

  if (!dataYaTrack) {
    Warn(element);
    return;
  }

  let scopeAncestors = [];
  while (element && element.tagName) {
    if (element.matches('[data-ya-scope]')) {
      scopeAncestors.push(element);
    }
    element = element.parentNode;
  }

  let tags = [srcEl].concat(scopeAncestors);
  for (const hierarchyElement of tags) {
    const hierarchyIdx: number = tags.indexOf(hierarchyElement);
    let tagVal = (hierarchyIdx == 0) ? dataYaTrack : hierarchyElement.dataset.yaScope
    if (tagVal.indexOf('#') > -1) {
      let attributeName = hierarchyIdx == 0 ? 'data-ya-track': 'data-ya-scope';
      let ancestor = (hierarchyIdx + 1 < tags.length) ? tags[hierarchyIdx + 1]: document;
      let siblings = Array.from(ancestor.querySelectorAll(`[${attributeName}='${tagVal}']`));
      for (const sibling of siblings) {
        const siblingIdx: number = siblings.indexOf(sibling);
        if (hierarchyElement == sibling) {
          tagVal = tagVal.replace('#', siblingIdx + 1);
          break;
        }
      }
    }
    tags[hierarchyIdx] = tagVal;
  }

  return tags.reverse().join('_');
};

/**
 * Builds a map of all event names on page.
 */
function CalcEventNameMap (): Map<string, HTMLElement[]> {
  const map: Map<string, HTMLElement[]> = new Map();
  const allLinks: HTMLElement[] = <HTMLElement[]> Array.from(document.links);
  const allOtherTracked: HTMLElement[] = <HTMLElement[]> Array.from(document.querySelectorAll('button, input, select, textarea'));
  for (const el of allLinks.concat(allOtherTracked)) {
    if (el.tagName.toLowerCase() == 'input' && (<HTMLInputElement> el).type == 'hidden') continue;
    const name = Instance.CalcEventNameForElement(el);
    if (!name) continue;
    if (!map.has(name)) {
      let container: HTMLElement[] = [];
      map.set(name, container);
    }
    const elements: HTMLElement[] = <HTMLElement[]> map.get(name);
    elements.push(el);
    map.set(name, elements);
  }
  return map;
}

export {
  SelectorTracking,
  GetParams,
  CheckAnchorQueries,
  SearchElementForSelector,
  CalcEventNameForElement,
  CalcEventNameMap
}
