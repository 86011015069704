import { Debug } from 'ts/components/Util/Debug';
import { CalcEventNameMap } from 'ts/components/YextAnalytics/Analytics';

function PrintEvents (): void {
  if (!Debug.isEnabled()) return;  
  for (const name of Array.from(CalcEventNameMap().keys())) {
    console.log(name);
  }
}

function Warn (target): void {
  if (!Debug.isEnabled()) return;
  console.warn('could not track element', target);    
}

function PrintEvent(eventName): void {
  if (!Debug.isEnabled()) return;
  console.log(`%c[YextAnalytics]%c- Fired event: ${eventName}`, 'color: blue;', 'color: black;');    
}

export {
  PrintEvent,
  PrintEvents,
  Warn
}