export function isVisible (element: HTMLElement): boolean {
  if (element == null) return false;
  let bounds: ClientRect = element.getBoundingClientRect();

  return 0 < (
        (bounds.left || 0)
      + (bounds.top || 0)
      + (bounds.height || element.offsetHeight)
      + (bounds.width || element.offsetWidth)
      + bounds.top
      + bounds.right
      + bounds.bottom
      + bounds.left
  );
};
