import { BaseMap } from './BaseMap.js';
import { OptimizedResizeInstance } from 'js/components/Util/OptimizedResize.js';

export class MapboxGL extends BaseMap {
  static initClass() {
    this.instances = [];
    this.providerLoaded = false;
    this.className = "Yext.Maps.MapboxGL";
  }
  constructor(args) {
    super(args);
    this.mapstyle = 'mapbox://styles/mapbox/streets-v9';
    this.options = {
      container: this.element.id,
      style: this.mapstyle,
      zoom: this.config.zoom,
      interactive: !this.config.disableMapControl
    };
    this.boundsOptions = {
      linear: true,
      duration: 0,
      animate: false,
      padding: this.BUFFER,
      maxZoom: 14
    };
    this.pins = [];
    this.bounds = [];
  }

  appendScript() {
    let baseUrl = "https://api.mapbox.com/mapbox-gl-js/v0.44.1/mapbox-gl";
    let link = document.createElement('link');
    let mapScript = document.createElement('script');
    link.rel = 'stylesheet';
    link.href = `${baseUrl}.css`;
    mapScript.src = `${baseUrl}.js`;
    mapScript.onload = () => {
      mapboxgl.accessToken = this.config.apiID;
      this.constructor.providerCallback();
    };
    document.head.appendChild(link);
    document.head.appendChild(mapScript);
  }

  localizeMap(map) {
    if (document.documentElement.hasAttribute('lang')) {
      const whiteList = [
        'en',
        'es',
        'fr',
        'de',
        'ru',
        'zh'
      ];
      const language = document.documentElement.getAttribute('lang');
      if (whiteList.indexOf(language) > -1) {
        const locale = `{name_${language}}`;
        for (let layer of Array.from(map.getStyle().layers)) {
        // HACK: As of v0.37.0 setting the localization label on road labels deletes the labels
        // Therefore, string check to just avoid setting the locale on road labels.
          if (((layer.layout != null ? layer.layout['text-field'] : undefined) != null) && (layer.id.indexOf('road') === -1)) {
            map.setLayoutProperty(layer.id, 'text-field', locale);
          }
        }
      }
    }
  }

  preparePin(i, loc, map, lngLat) {
    let icon = this.iconImage(loc, i);

    let wrapper = document.createElement('div');
    let link = document.createElement('a');
    link.className = 'custom-icon-link';
    let url = `${this.config.baseUrl}${loc.url}`;
    if (this.config.linkToGetDirections) {
      url = loc.get_directions_url;
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'nofollow noopener noreferrer');
    }
    link.setAttribute('href', url);
    link.setAttribute('data-ya-track', 'drivingdirection');

    let image = document.createElement('img');
    image.setAttribute('alt', `${loc.altTagText}`);
    image.src = icon;
    image.setAttribute('width', `${this.pinWidth}`);
    image.setAttribute('height', `${this.pinHeight}`);

    link.appendChild(image);
    wrapper.appendChild(link);
    wrapper.setAttribute('data-lon', loc.longitude);
    wrapper.setAttribute('data-lat', loc.latitude);
    wrapper.className = 'custom-icon js-custom-icon';

    let marker = new mapboxgl.Marker(wrapper, {offset: new mapboxgl.Point(-this.pinWidth/2, -this.pinHeight/2)}).setLngLat(lngLat);
    return marker;
  }

  prepareMap() {
    let marker;
    let map = new mapboxgl.Map(this.options);
    this.bounds = new mapboxgl.LngLatBounds();
    let idx = 0;
    for (let location of Array.from(this.allLocations)) {
      let coord = new mapboxgl.LngLat(location.longitude, location.latitude);
      marker = this.preparePin(idx, location, map, coord);
      this.pins.push(marker);
      this.bounds.extend(coord);
      idx++;
    }

    if (this.allLocations.length > 0) {
      this.options.center = this.bounds.getCenter();
    } else {
      this.options.center = new mapboxgl.LngLat(this.longitude, this.latitude);
      
      map.setCenter(this.options.center);
      map.setZoom(this.options.zoom);
    }

    if (!this.config.disableMapControl) {
      map.addControl(new mapboxgl.NavigationControl());
    }

    map.on('load', () => {
      if (this.pins.length > 0) {
        for (marker of Array.from(this.pins)) {
          marker.addTo(map);
        }
        map.fitBounds(this.bounds, this.boundsOptions);
      }

      this.localizeMap(map);

      this.mapLoaded();
    });

    OptimizedResizeInstance.on( () => {
      if (this.allLocations.length == 0) return;
      return map.fitBounds(this.bounds, this.boundsOptions);
    });

    return map;
  }
}

MapboxGL.initClass();
