interface Point {
  latitude: number,
  longitude: number,
}

interface DistanceCalculatorConfig {
  useKilometers?: boolean;
  precision?: number;
  formatter?: (number: number) => string;
}

const earthRadiusInMiles = 3959;
const earthRadiusInKilometers = 6371;

export class DistanceCalculator {
  useKilometers: boolean;
  precision: number;
  formatter?: (num: number) => string;

  constructor(conf?: DistanceCalculatorConfig){
    conf = conf || {};
    this.useKilometers = conf.useKilometers || false;
    this.precision = conf.precision || 2;
    this.formatter = conf.formatter || ((d: number) => String(d.toFixed(this.precision)));
  }

  haversineDistance(source: Point, dest: Point) {
    const earthRadius = this.useKilometers ? earthRadiusInKilometers : earthRadiusInMiles;

    const lat1Rads = this.degreesToRadians(source.latitude);
    const lat2Rads = this.degreesToRadians(dest.latitude);
    const lon1Rads = this.degreesToRadians(source.longitude);
    const lon2Rads = this.degreesToRadians(dest.longitude);

    const deltaLat = this.degreesToRadians(dest.latitude - source.latitude);
    const deltaLon = this.degreesToRadians(dest.longitude - source.longitude);

    const a = Math.pow(Math.sin(deltaLat/2), 2) + (Math.cos(lat1Rads) * Math.cos(lat2Rads) * Math.pow(Math.sin(deltaLon/2), 2));
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return earthRadius * c;
  }

  degreesToRadians(degrees: number) {
    return (degrees * Math.PI) / 180;
  }

  calculateDistances(dataset) {
    if (!dataset.latitude || !dataset.longitude) return;
    const source = {
      latitude: dataset.latitude * 1,
      longitude: dataset.longitude * 1,
    };

    const containers = <HTMLCollectionOf<HTMLElement>> document.getElementsByClassName('c-js-distance-container');
    Array.from(containers)
    .forEach((container) => {
      const currentPoint = {
        latitude: +container.dataset.latitude,
        longitude: +container.dataset.longitude,
      };

      const distance = this.haversineDistance(source, currentPoint);

      container.setAttribute('data-distance', this.formatter(distance));
      container.innerText = distance.toFixed(this.precision);
      container.setAttribute('data-distance', String(distance));
      container.classList.add('got-distance');
    })
  }
}
