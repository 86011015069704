import 'babel-polyfill';
import createFocusTrap from 'focus-trap/index.js';
import { PinClickEvent } from './Events.js';
import { OptimizedResizeInstance } from 'js/components/Util/OptimizedResize.js';
import { MapboxGL } from 'js/components/Maps/MapboxGL.js';
import { Maps } from 'js/components/Maps/index.js';

export class SearchMap extends MapboxGL {
  static initClass() {
    Maps.FactoryForProvider.MapboxGL = (data) => new this(data);
    this.instances = [];
    this.providerLoaded = false;
    this.className = "Yext.Maps.SearchMap";
    this.breakPoint = 992;
  }

  constructor(args) {
    super(args);
    this.options = {
      container: this.element.id,
      style: 'mapbox://styles/mapbox/streets-v9',
      zoom: this.config.zoom,
      interactive: true
    };
    this.boundsOptions = {
      linear: true,
      duration: 0,
      animate: false,
      padding: this.BUFFER,
      maxZoom: 14,
      offset: [this.calcOffset(), 0]
    };
    this.pins = [];
    this.bounds = [];
    this.infowindow = null;
    this.pinHeight = 45;
    this.pinWidth = 34;
  }

  calcOffset() {
    let searchcontent = document.querySelector('.search-content-container');
    if ((searchcontent != null) && (window.matchMedia(`(min-width: ${this.constructor.breakPoint}px`).matches)) {
      let mapOffset = searchcontent.offsetLeft + searchcontent.offsetWidth;
      return mapOffset/2;
    }
    return 0;
  }

  iconImage(loc, i, backgroundColor, textColor) {
    return `data:image/svg+xml;charset=utf8,${encodeURIComponent(this.svgPin(i+1, backgroundColor, textColor))}`;
  }

  clickHandler(loc, latLng, index, map) {

    let target = `js-yl-${loc.id}`;
    document.dispatchEvent(new PinClickEvent(target));

    let infoContentTemplate = document.querySelector(`#${target} > .js-infowindow`);
    if (infoContentTemplate == null) { return; }

    if (window.matchMedia(`(max-width: ${this.constructor.breakPoint - 1}px)`).matches) {
      let mobileInfo = document.getElementById('js-mobile-info');
      mobileInfo.innerHTML = infoContentTemplate.innerText;
      map.panTo(latLng);
      window.setTimeout((function() {
        let overlay = document.getElementById('js-overlay');
        overlay.classList.add('is-active');
        return overlay.classList.add('in');
      }), 20);
      Yext.Hours.loadHoursData();
      if (Yext.Hours.autoRunInstances) { Yext.Hours.runInstances(); }
    } else {
      this.setPopup(infoContentTemplate.text, latLng, map);
    }
  }

  setPopup(content, latLng, map) {
    let linearOffset = 0;
    let popupOffsets = {
      'top': [0, 0],
      'top-left': [0,0],
      'top-right': [0,0],
      'bottom': [0, -this.pinHeight/2],
      'bottom-left': [linearOffset, ((this.pinHeight - this.pinWidth) + linearOffset) * -1],
      'bottom-right': [-linearOffset, ((this.pinHeight - this.pinWidth) + linearOffset) * -1],
      'left': [this.pinWidth/2, (this.pinHeight - this.pinWidth) * -1],
      'right': [-this.pinWidth/2, (this.pinHeight - this.pinWidth) * -1]
    };
    if (this.infowindow !== null) { this.infowindow.remove(); }
    this.infowindow = new mapboxgl.Popup({closeOnClick:false, offset: popupOffsets})
      .setHTML(content)
      .setLngLat(latLng)
      .addTo(map);
    if (window.matchMedia(`(max-width: ${this.constructor.breakPoint - 1}px)`).matches) {
      map.setCenter(latLng);
    }
    Yext.Hours.loadHoursData();
    if (Yext.Hours.autoRunInstances) { return Yext.Hours.runInstances(); }
  }

  preparePin(i, loc, map, latLng) {
    let icon = this.iconImage(loc, i);

    let wrapper = document.createElement('div');
    let button = document.createElement('button');
    button.className = 'custom-icon-button';
    let image = document.createElement('img');
    image.setAttribute('alt', `${loc.altTagText}`);
    image.src = icon;
    image.setAttribute('width', this.pinWidth);
    image.setAttribute('height', this.pinHeight);
    image.className = 'custom-icon-button-image';

    button.setAttribute('data-lon', loc.longitude);
    button.setAttribute('data-lat', loc.latitude);
    button.onclick = () => {
      this.clickHandler(loc, latLng, i, map);
    };

    button.appendChild(image);
    wrapper.appendChild(button);
    wrapper.className = 'custom-icon js-custom-icon';

    wrapper.isDefault = true;

    document.addEventListener(PinClickEvent.eventTypeName, event => {
      let myTarget = `js-yl-${loc.id}`;
      let needsHighlight = event.detail.yextId === myTarget;
      let hasHighlight = !wrapper.isDefault;
      if (needsHighlight && !hasHighlight) {
        image.src = this.iconImage(loc, i, '#FFFFFF', '#CF012E');
        wrapper.isDefault = false;
        wrapper.style.zIndex = "1";
      } else if (!needsHighlight && hasHighlight) {
        image.src = this.iconImage(loc, i, '#CF012E');
        wrapper.isDefault = true;
        wrapper.style.zIndex = "0";
      }
      else {}
        //do nothing
    });
    let marker = new mapboxgl.Marker(wrapper, {offset: new mapboxgl.Point(-this.pinWidth/2, -this.pinHeight/2)}).setLngLat(latLng);
    return marker;
  }

  prepareMap() {
    let marker;
    let map = new mapboxgl.Map(this.options);
    this.bounds = new mapboxgl.LngLatBounds();
    let idx = 0;
    for (let location of Array.from(this.allLocations)) {
      let coord = new mapboxgl.LngLat(location.longitude, location.latitude);
      marker = this.preparePin(idx, location, map, coord);

      this.pins.push(marker);
      this.bounds.extend(coord);
      idx++;
    }

    if (this.allLocations.length > 0) {
      this.options.center = this.bounds.getCenter();
    } else {
      this.options.center = new mapboxgl.LngLat(this.longitude, this.latitude);
      this.options.zoom = 5;
    }

    map.setCenter(this.options.center);
    map.setZoom(this.options.zoom);

    if (!this.config.disableMapControl) {
      map.addControl(new mapboxgl.NavigationControl());
    }

    map.on('load', () => {
      if (this.pins.length > 0) {
        for (marker of Array.from(this.pins)) {
          marker.addTo(map);
        }
        map.fitBounds(this.bounds, this.boundsOptions);
      }

      this.mapLoaded();
    });

    OptimizedResizeInstance.on( () => {
      if (window.matchMedia(`(max-width: ${this.constructor.breakPoint - 1}px)`).matches) {
        if (this.infowindow !== null) { this.infowindow.remove(); }
      } else {
        this.boundsOptions['offset'] = [this.calcOffset(), 0];
      }
      if (this.allLocations.length == 0) return;
      return map.fitBounds(this.bounds, this.boundsOptions);
    });

    return map;
  }
}
SearchMap.initClass();

let registerClickHandlers = function() {
  let results = document.getElementsByClassName('js-location-result');
  for (let result of Array.from(results)) {
    result.addEventListener('click', function() {
      const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.SearchMap.breakPoint}px)`);
      if (!minWidthSearchMapBreakPointQuery.matches) { return; }
      document.dispatchEvent(new PinClickEvent(this.getAttribute('id')));
    });
    result.addEventListener('mouseover', function() {
      const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.SearchMap.breakPoint}px)`);
      if (!minWidthSearchMapBreakPointQuery.matches) { return; }
      document.dispatchEvent(new PinClickEvent(this.getAttribute('id')));
    });
  }

  let buttons = document.getElementsByClassName('custom-icon-button');
  for (let button of Array.from(buttons)) {
    button.addEventListener('click', function() {
      const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.SearchMap.breakPoint}px)`);
      if (!minWidthSearchMapBreakPointQuery.matches) { return; }
      document.dispatchEvent(new PinClickEvent(this.getAttribute('id')));
    });
  }

  document.addEventListener(PinClickEvent.eventTypeName, function(e) {

    let id = e.detail.yextId;
    if (id == null) { return; }
    document.getElementById(id).classList.add('is-active');
    let toRemove = document.querySelectorAll(`.js-location-result.is-active:not(#${id})`);
    for (let toChange of Array.from(toRemove)) {
      toChange.classList.remove('is-active');
    }

    let container = $('.location-list-results');
  });

};

let registerMapToggling = function() {
  let buttons = document.querySelectorAll('.js-result-toggle');
  for (var button of Array.from(buttons)) {
    button.addEventListener('click', function() {
      document.body.classList.toggle('map-visible');
      document.dispatchEvent(new Event('toggle-map'));
    });
  }

  document.addEventListener('toggle-map', function() {
    for (button of Array.from(buttons)) {
      let disabled = button.getAttribute('disabled');
      if (disabled != null) { button.removeAttribute('disabled'); }
      if ((disabled == null)) { button.setAttribute('disabled', ''); }
    }
  });
};

let setupOverlayAnimation = function() {
  let overlay = document.getElementById('js-overlay');
  overlay.addEventListener('click', function(e) {
    if (e.target !== this) { return; }
    return this.classList.add('out');
  });

  let listener = function(e){
    if (e.type !=="animationend") { return; }
    switch (e.animationName) {
      case "overlay-in":
        overlay.classList.add('in-finished');
        overlay.classList.remove('in');

        // add a listener to the mobile pop up for a close on button click
        let infoWindowClose = document.getElementById('info-window-close');

        infoWindowClose.addEventListener('click', () => overlay.classList.add('out'));

        // set up a focus trap on the mobile info window
        let infoWindow = document.getElementById('js-info-window');
        let focusTrap = createFocusTrap('.info-window', {
          onActivate() {
            infoWindow.addClass = 'trap is-active';
          },
          onDeactivate() {
            infoWindow.removeClass = 'is-active';
          },
          clickOutsideDeactivates: true
        }
        );
        focusTrap.activate();

        // deactivate focus trap when close button is clicked
        document.getElementById('info-window-close').addEventListener('click', function() {
          focusTrap.deactivate();
        });

        // allow escaping of mobile info window with the esc key
        window.onkeyup = function(e) {
          let key = e.keyCode ? e.keyCode : e.which;
          if (key === 27) {
            overlay.classList.add('out');
          }
        };
        break;

      case "overlay-out":
        overlay.classList.remove('is-active');
        overlay.classList.remove('in-finished');
        overlay.classList.remove('out');
        break;
    }
  };

  return overlay.addEventListener('animationend', listener, false);
};

export const MapSetup = () => {
  registerClickHandlers();
  registerMapToggling();
  setupOverlayAnimation();
}
