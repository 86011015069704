import { CalcEventNameForElement } from 'ts/components/YextAnalytics/Analytics.ts';
import {
  YEXT_EVENT_MAPPING,
  VIDEOTRON_EVENT_NAME,
  VIDEOTRON_EVENT_CATEGORY,
  GTMEvents
} from 'js/common/modules/analytics/GTMEvents';

export function fireGTMEvent(eventInfo) {
  window.dataLayer.push({
    event: VIDEOTRON_EVENT_NAME,
    eventCategory: VIDEOTRON_EVENT_CATEGORY,
    ...eventInfo
  });
};

export function trackYextEvents() {
  document.addEventListener('click', e => {
    const yextEventName = CalcEventNameForElement(e.target);

    if (yextEventName && yextEventName in YEXT_EVENT_MAPPING) {
      const event = YEXT_EVENT_MAPPING[yextEventName];
      let resultLabel = null;

      if (typeof event.eventLabel === 'function') {
        resultLabel = event.eventLabel(e.target);
      }
      else if (typeof event.eventLabel === 'string') {
        resultLabel = event.eventLabel;
      }

      if (resultLabel !== null)  {
        fireGTMEvent(GTMEvents.GenericEvent(event.eventAction, resultLabel));
      }
    }
  }, true);
};
