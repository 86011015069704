import 'babel-polyfill';
import 'script-loader!node_modules/jquery/dist/jquery.min.js';

// uncomment if you need to do expand/collapse:
// import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/collapse.js';
// import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/transition.js';

import { Global } from 'js/common/global.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { Yext } from 'js/components/Soy2js/Soy2js.js';

import { GoogleAnalytics } from 'js/components/GoogleAnalytics/index.js';
import { LocationHours } from 'js/components/LocationHours/index.js';
import { Maps } from 'js/components/Maps/index.js';

import { Instance as MapsAPI } from 'js/components/Maps/GoogleMapsAPI.js';
Yext.Maps = Maps;

import { LocatorCobalt } from 'js/search/modules/CobaltModified/LocatorCobalt.js';
import { Carousel } from 'js/common/modules/Carousel.js';

import { GTMPromoHandler } from 'js/search/modules/GTMPromoHandler.js';

Global.init();
GoogleAnalytics.enableAutotracking('yext');

OnReady(() => {
  LocatorCobalt.init();

  const promoHandler = new GTMPromoHandler();
  promoHandler.init();

  new Carousel(document.querySelector('.Carousel'), {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    prevArrow: '<span class="slick-prev slick-arrow"><img class="slick-arrow-image" src="/assets/images/prev.svg" alt="Photo Carousel Previous"></span>',
    nextArrow: '<span class="slick-next slick-arrow"><img class="slick-arrow-image" src="/assets/images/next.svg" alt="Photo Carousel Next"></span>',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          infinite: true,
          dots: false,
          arrows: true
        }
      }
    ]
  });

  // hide regions if the link 404s
  const regions = document.querySelectorAll('.Directory-listLinkText');
  for (const region of regions) {
    const href = region.href;
    $.ajax({
      url: href,
      error: function () {
        region.parentElement.style.display = 'none';
      }
    })
  }
});
