import 'intersection-observer';
import { fireGTMEvent } from 'js/common/modules/analytics/GTMAnalytics.js';
import { GTMEvents } from 'js/common/modules/analytics/GTMEvents.js';

export class GTMPromoHandler
{
  constructor() {
    this.promoElement = document.querySelector('.Locator-promo .Promo');
    this.promoInfo = this.promoElement.dataset;
    this.onChange = this.onChange.bind(this);
  }

  init() {
    this.promoElement.addEventListener('click', () =>
      fireGTMEvent(GTMEvents.PromoEvent('click', this.promoInfo))
    );

    const observer = new IntersectionObserver(this.onChange, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    });
    observer.observe(this.promoElement);
  }

  onChange(entries, observer) {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        fireGTMEvent(GTMEvents.PromoEvent('impression', this.promoInfo))
        observer.unobserve(this.promoElement);
      }
    });
  }

}
