import { FilterModal as FilterModalBase } from 'js/components/Cobalt/Locator/FilterModal.js';
const createFocusTrap = require('focus-trap/index.js');

export class FilterModal extends FilterModalBase {
  constructor(filtersWrapper, triggerEl) {
    super(filtersWrapper, triggerEl);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  };

  bindFilters() {
    if (this.filtersBound) { return; }
    this.filtersBound = true;

    for (const clearButton of this.filtersWrapper.querySelectorAll('.js-filters-clear')) {
      clearButton.addEventListener('click', this.clearAll.bind(this));
    }

    for (const toggleButton of document.querySelectorAll('.js-filters-open')) {
      toggleButton.onclick = this.open.bind(this);
    }

    for (const toggleButton of this.filtersWrapper.querySelectorAll('.js-filters-close')) {
      toggleButton.onclick = this.close.bind(this, true);
    }

    for (const toggleButton of this.filtersWrapper.querySelectorAll('.js-filters-apply')) {
      toggleButton.onclick = this.close.bind(this, false);
    }

    const focusTrapEl = this.filtersWrapper.querySelector('.js-filters-focus');
    if (focusTrapEl) {
      this.focusTrap = createFocusTrap(focusTrapEl, { clickOutsideDeactivates: true });
    }

    for (const input of this.inputs) {
      input.addEventListener('change', this.updateApplyButtonState.bind(this));
    }
  }

  open() {
    super.open();
    document.addEventListener('click', this.handleClickOutside);
  }

  close(restoreState) {
    super.close(restoreState);
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside(e) {
    if (e.target.id === 'filters-modal') {
      this.close(true);
    }
  }
}
