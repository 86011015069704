export class Autocompleter {
  constructor(input) {
    this.input = input;
    this.geoCoder = new google.maps.Geocoder();
    this.autocomplete = new google.maps.places.Autocomplete(this.input, {
      types: ['geocode'],
      componentRestrictions: {
        country: 'US'
      }
    });
    this.initialValue = this.input.value;
  }
}

export class AutocompleterWithInputSwitch extends Autocompleter {
  constructor(input, form) {
    super(input);
    this.form = form;
    this.autocomplete.addListener('place_changed', ()=>{
      this.changeHandler()
    });
    this.initialValue = this.input.value;
  }

  changeHandler() {
    let options = {
      address: this.input.value
    };
    this.geoCoder.geocode(options, (res, stat)=>{
      let reversed = this.reverse(res, stat);
      if (reversed == null) {
        return
      }
      this.input.name = "qp";
      let q = document.createElement('input');
      q.name ="q";
      q.type = "hidden";
      q.value = reversed;
      this.form.appendChild(q);
    });
  }

  reverse(results, status) {
    if (status != google.maps.GeocoderStatus.OK) {
      return null
    }
    if (results == null || results.length == 0) {
      return null
    }
    return results[0].geometry.location.lat() + ', ' + results[0].geometry.location.lng();
  }
}
