import 'babel-polyfill';
import stickybits from 'stickybits';
import URI from 'urijs';

import { Maps } from 'js/components/Maps/index.js'
import { Locator } from 'js/search/modules/CobaltModified/Locator--override.js'
import { Form } from 'js/components/Cobalt/Locator/Form.js';
import { FilterModal } from 'js/search/modules/CobaltModified/FilterModal.js';
import { Instance as RendererCobalt } from './RendererCobalt.js';
import { Autocompleter } from 'js/common/modules/Autocompleter.js';
import { fireGTMEvent } from 'js/common/modules/analytics/GTMAnalytics.js';
import { GTMEvents } from 'js/common/modules/analytics/GTMEvents.js';

import { PanButton } from 'js/search/modules/PanButton.js';
import { CobaltMap as CobaltMapMapbox, MapSetup as MapSetupMapbox } from 'js/search/modules/LocatorCobaltMap.js';
import { CobaltMap as CobaltMapGoogle, MapSetup as MapSetupGoogle } from 'js/search/modules/LocatorCobaltMapGoogle.js';
import { Instance as MapsAPI, LibraryTypes as MapsLibraryTypes } from 'js/components/Maps/GoogleMapsAPI.js';

export class LocatorCobalt {
  constructor() {}

  static init(options = {}) {
    Maps.loadAndRun();
    const configEl = document.getElementById('js-cobalt-config');
    const config = configEl ? JSON.parse(configEl.innerHTML) : {};
    const providerIsGoogle = () => {
      return (config.provider && config.provider.toLowerCase() === 'google')
    }

    if (providerIsGoogle()) {
      window.Yext.Maps = { CobaltMap: CobaltMapGoogle};
      MapSetupGoogle();
    } else {
      window.Yext.Maps = { CobaltMap: CobaltMapMapbox};
      MapSetupMapbox();
    }

    const locatorEl = document.getElementById('js-locator');
    const formEl = document.getElementById('search-form');
    const applyFilterEl = document.querySelector('.js-filters-apply');

    const callback = (mutationsList, observer) => {
      for(let mutation of mutationsList) {
        for (let node of mutation.addedNodes) {
          if (node && node.classList && node.classList.contains('pac-container')) {
            formEl.appendChild(node);
            observer.disconnect();
          }
        }
      }
    };
    const observer = new MutationObserver(callback);
    observer.observe(document.body, {childList: true});

    MapsAPI.addLibrary(MapsLibraryTypes.Places);
    MapsAPI.loaded().then(() => {
      new Autocompleter(document.getElementById('q'), locatorEl);
    });

    const formSubmitter = document.getElementById('js-form-submit');
    const filtersEl = document.getElementById('filters')
    const externalInputEls = filtersEl ? filtersEl.querySelectorAll('input,select') : [];

    const filterModalOpenEl = document.getElementById('js-filters-open');
    const filterModal = filtersEl ? new FilterModal(filtersEl, filterModalOpenEl) : null;
    const form = new Form(formEl, formSubmitter, externalInputEls, config.orSearchParams);

    // Polyfill for position sticky on IE.
    // This only works on mobile. It works on the body but can't handle overflowing containers.
    stickybits('.js-stickybits', {noStyles: true});
    const locatorCobalt = new Locator(locatorEl,
            form,
            RendererCobalt,
            filterModal && filterModal.updateFilterCount.bind(filterModal),
            config);

    applyFilterEl.addEventListener('click', () => {
      const filterCheckboxes = Array.from(externalInputEls);
      fireGTMEvent(GTMEvents.ApplyFiltersEvent(filterCheckboxes));

      locatorCobalt.geolocate(locatorCobalt.inputEl, false, false);
    });

    const researchFunction = (map, center) => {
      let formValues = form.serialize();
      let params = new URI(`?${formValues}`);
      params.removeSearch('q');
      params.addSearch('q', `${center.lat},${center.lng}`);
      locatorCobalt.searchAndCallback(params.query(), (data) => {
        RendererCobalt.render(data, {keepCenterVisible: true})
      });
    };

    const researcher = new PanButton(researchFunction, {
      buttonClass: 'Locator-researchButton',
    });

    if (options.useReSearchButton) {
      const instances = providerIsGoogle() ? CobaltMapGoogle.instances : CobaltMapMapbox.instances;
      for (const instance of instances) {
        instance.element.addEventListener('map.prepared', () => {
          researcher.addToMap(instance, instance.element);
        });
      }
    }
  }
}
