export class Debug {
  static param: string = 'xYextDebug'

  static hasQueryParam(): boolean {
    if ('URL' in (<any> window) && typeof URL === "function") {
      let params = new URL((<any> window).location.href).searchParams;
      return params && params.get(this.param) == 'true';
    }
    return false;
  }

  static enable(): void {
    document.documentElement.classList.add(this.param);
  }

  static disable():void {
    document.documentElement.classList.remove(this.param);
  }

  static isEnabled(): boolean {
    let enabled = this.hasQueryParam();
    if (enabled) {
      this.enable();
    }
    return enabled;
  }
}
