import { MonitoringInit } from 'js/components/Monitoring/Monitoring';
import { Polyfills } from 'js/components/Polyfills/Polyfills';
import { Instance } from 'js/components/YextAnalytics/Analytics';
import { AccessibilityChecks } from 'js/components/Util/Accessibility';
import { OnReady } from 'js/components/Util/Browser';
import { ImageObjectFit } from 'js/components/Image';
import { fireGTMEvent, trackYextEvents } from 'js/common/modules/analytics/GTMAnalytics';
import { GTMEvents } from 'js/common/modules/analytics/GTMEvents';
import { Debug } from "ts/components/Util/Debug.ts";
import { Instance as WCAGNewTab } from "js/components/Util/WcagNewTab";
import { AnalyticsDebugger } from "ts/components/AnalyticsDebugger/AnalyticsDebugger.ts";
import 'script-loader!node_modules/svg4everybody/dist/svg4everybody.min.js';

export class Global {
  static init() {
    Polyfills.init();
    MonitoringInit();

    OnReady(() => {
      Yext.Analytics = Instance;

      window.fireGTMEvent = fireGTMEvent;
      window.GTMEvents = GTMEvents;
      trackYextEvents();

      const thisYear = new Date().getFullYear();

      const dateSpans = document.querySelectorAll('.c-copy-date');
      for (const dateSpan of dateSpans) {
        dateSpan.innerHTML = `${thisYear}`;
      }
      ImageObjectFit();

      svg4everybody();
      WCAGNewTab.wcagify();

      if (Yext.isStaging ||
          window.location.href.includes('yextpages') ||
          window.location.href.includes('localhost')) {
        AccessibilityChecks.checkAltTags();
      }

      if (Debug.isEnabled()) {
        window.Debugger = new AnalyticsDebugger();
      }

      // TODO (skleyman) come back to this and see if we can use Header.js instead
      const headerButtonEl = document.getElementById('Header-toggle');
      headerButtonEl.addEventListener('click', () => {
        document.body.classList.toggle('isOpen');
      });
    });
  }
}
